import { Box, TextProps } from '@chakra-ui/react';

type RelativeSizes = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
type LiteralSizes =
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x';

export type IconProps = TextProps & {
  style: string;
  icon: string;
  size?: RelativeSizes | LiteralSizes;
};

const Icon: React.FC<IconProps> = ({
  style = 'solid',
  icon,
  size = 'md',
  className = '',
  ...props
}) => (
  <Box
    as="i"
    className={`fa-${style} fa-${icon} fa-${size} ${className}`}
    {...props}
  />
);

type SanityIconProps = Omit<IconProps, 'icon'> & {
  brands_icon?: string;
  duotone_icon?: string;
  light_icon?: string;
  regular_icon?: string;
  solid_icon?: string;
};

export const SanityIcon: React.FC<SanityIconProps> = ({
  style,
  brands_icon,
  duotone_icon,
  light_icon,
  regular_icon,
  solid_icon,
  ...props
}) => {
  const icons = {
    brands_icon,
    duotone_icon,
    light_icon,
    regular_icon,
    solid_icon,
  };
  const icon = icons[`${style}_icon` as keyof typeof icons];

  if (!icon) return null;

  return <Icon style={style} icon={icon} {...props} />;
};

export default Icon;
export * from './product-icons';
