import React from 'react';
import {
  Box,
  Flex,
  Image,
  Link,
  LinkBox,
  LinkOverlay,
  Text,
} from '@chakra-ui/react';
import NextLink from 'next/link';

import { useIntercom } from '@components/Analytics/Intercom';
import { sanityImage } from '@lib/integrations/sanity';
import type { CommonDocument } from '@raise/sanity';

type LeftMenu = CommonDocument['header']['left'][number];

export const SpecificLink: React.FC<{
  href?: string;
  children: React.ReactElement;
}> = ({ href, children }) => {
  const intercom = useIntercom();

  if (!href) {
    return children;
  }

  if (href === 'INTERCOM') {
    return React.cloneElement(children, {
      cursor: 'pointer',
      onClick: intercom.show,
    });
  }
  if (href.includes('http://') || href.includes('https://')) {
    return React.cloneElement(children, { href, target: '_blank' });
  }

  return (
    <NextLink href={href} passHref legacyBehavior>
      {children}
    </NextLink>
  );
};

export const FullLink: React.FC<
  LeftMenu['menu']['primary'][number] & {
    color: LeftMenu['color'];
    onClose: () => void;
  }
> = ({ title, description, link, color, image, onClose }) => {
  const sColor = color.value.split('.');

  return (
    <LinkBox
      _hover={{ bg: `${sColor[0]}.100` || 'gray.100' }}
      borderRadius="md"
      transitionProperty="background"
      transitionDuration="0.2s"
      w="full"
      p={4}
      onClick={onClose}
    >
      <Flex alignItems="center">
        <Image src={sanityImage(image).url()} w={24} mr={8} alt={title} />
        <Box>
          <SpecificLink href={link}>
            <LinkOverlay fontWeight="semibold">{title}</LinkOverlay>
          </SpecificLink>
          <Text color="gray.500">{description}</Text>
        </Box>
      </Flex>
    </LinkBox>
  );
};

export const NormalLink: React.FC<
  LeftMenu['menu']['secondary'][number] & {
    color: LeftMenu['color'];
    onClose: () => void;
  }
> = ({ title, color, link, onClose }) => (
  <SpecificLink href={link}>
    <Link
      colorScheme={color.value.split('.')[0]}
      variant="animated"
      fontWeight="semibold"
      onClick={onClose}
    >
      {title}
    </Link>
  </SpecificLink>
);
