import type { IconsSharedObject } from '@raise/sanity';

type AccountType = 'investment' | 'saving' | 'education';

export const getIconByType = (type: AccountType): IconsSharedObject => {
  switch (type) {
    case 'investment':
      return {
        style: 'duotone',
        duotone_icon: 'chart-line-up',
        _type: 'icon',
      };
    case 'saving':
      return {
        style: 'duotone',
        duotone_icon: 'money-bill-1',
        _type: 'icon',
      };
    case 'education':
      return {
        style: 'duotone',
        duotone_icon: 'user-graduate',
        _type: 'icon',
      };
    default:
      return {
        style: 'duotone',
        duotone_icon: 'circle-question',
        _type: 'icon',
      };
  }
};

export const getIconColorByType = (
  type: AccountType,
): { icon: string; hover: string; active: string } | undefined => {
  switch (type) {
    case 'investment':
      return {
        icon: 'yellow.400',
        hover: 'yellow.100',
        active: 'yellow.200',
      };
    case 'saving':
      return {
        icon: 'teal.400',
        hover: 'teal.100',
        active: 'teal.200',
      };
    case 'education':
      return {
        icon: 'blue.500',
        hover: 'blue.100',
        active: 'blue.200',
      };
    default:
      return undefined;
  }
};
