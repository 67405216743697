import type { Metadata, ResolvingMetadata } from 'next';

import { sanityImage } from './client';

export const localeData = {
  locales: ['en', 'es'],
  locale: 'en',
  defaultLocale: 'en',
};

export const noDraft = `!(_id in path('drafts.**'))`;
export const locale = `language == $locale && ${noDraft}`;
export const defaultLocale = `language == $defaultLocale && ${noDraft}`;

export const coalesce = (document: string, extra?: string) => `coalesce(
  *[_type == "${document}" && ${locale} ${extra || ''}][0],
  *[_type == "${document}" && ${defaultLocale} ${extra || ''}][0]
)`;

export const generateSanityMetadata = async (
  parent: ResolvingMetadata,
  meta?: { title?: string; description?: string; image?: any } | string,
): Promise<Metadata> => {
  const metadata: Metadata = {};

  if (typeof meta != 'string' && meta?.title) {
    metadata.title = meta.title;
  }

  if (typeof meta != 'string' && meta?.description) {
    metadata.description = meta.description;
  }

  if (typeof meta != 'string' && meta?.image) {
    const openGraph = (await parent).openGraph || {};

    metadata.openGraph = {
      ...openGraph,
      images:
        typeof meta.image === 'string'
          ? meta.image
          : sanityImage(meta.image).url(),
    };
  }

  return metadata;
};
