'use client';

import { createContext, RefObject, useContext, useRef } from 'react';
import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react';

const MobileContext = createContext<{
  modal: UseDisclosureReturn;
  buttonRef: RefObject<HTMLButtonElement>;
} | null>(null);

export function MobileContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const modal = useDisclosure();
  const buttonRef = useRef(null);

  return (
    <MobileContext.Provider value={{ modal, buttonRef }}>
      {children}
    </MobileContext.Provider>
  );
}

export function useMobileContext() {
  return useContext(MobileContext);
}
