import { Link, UseDisclosureReturn } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useSession } from 'next-auth/react';

import { GetDataProps } from '../types';

export default function SessionLinks({
  common,
  modal,
}: {
  common: GetDataProps['common'];
  modal: UseDisclosureReturn;
}) {
  const { status } = useSession();
  const session = status === 'authenticated';

  const links = common?.header.right;

  return (
    <>
      {!session &&
        links.map((link) => (
          <Link
            onClick={modal?.onClose}
            as={NextLink}
            key={link.title}
            href={link.link}
            fontWeight="semibold"
            display="block"
            fontSize="xl"
            py={2}
            color="gray.800"
            _hover={{ color: 'primary.500' }}
          >
            {link.title}
          </Link>
        ))}
    </>
  );
}
