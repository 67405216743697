import React from 'react';
import { Container, ContainerProps } from '@chakra-ui/react';

export const pageContainerProps = {
  maxW: '7xl',
  mx: 'auto',
  px: [4, 6],
};

const PageContainer: React.FC<ContainerProps> = ({ children, ...props }) => (
  <Container {...pageContainerProps} {...props}>
    {children}
  </Container>
);

export default PageContainer;
