'use client';

import { Modal, ModalBody, ModalContent, ModalHeader } from '@chakra-ui/react';

import MobileContent from './Content';
import MobileNavbar from './Navbar';
import { useMobileContext } from './Provider';

import { GetDataProps } from '../types';

export default function MobileModal({
  logo,
  common,
}: {
  logo: any;
  common: GetDataProps['common'];
}) {
  const props = useMobileContext();
  if (!props) return null;

  const { modal, buttonRef } = props;

  return (
    <Modal {...modal} motionPreset="none" size="full" finalFocusRef={buttonRef}>
      <ModalContent borderRadius="none">
        <ModalHeader p={0}>
          <MobileNavbar logo={logo} />
        </ModalHeader>
        <ModalBody px={0}>
          <MobileContent modal={modal} common={common} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
