import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Image,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react';

import { sanityImage } from '@lib/integrations/sanity';

import { PrimaryMenu, SecondaryMenu } from '../Menus';
import { GetDataProps } from '../types';

export default function LeftLinks({
  modal,
  common,
}: {
  modal: UseDisclosureReturn;
  common: GetDataProps['common'];
}) {
  const links = common?.header.left;

  return (
    <>
      {links.map((category) => (
        <AccordionItem key={category.title} border="0px">
          <AccordionButton px={0} py={2} _focus={{ bg: 'transparent' }}>
            <Flex justifyContent="space-between" alignItems="center" w="full">
              <Flex alignItems="center">
                <Image
                  src={sanityImage(category.image).url()}
                  boxSize={12}
                  alt={category.title}
                  mr={6}
                />
                <Text fontWeight="semibold" fontSize="xl">
                  {category.title}
                </Text>
              </Flex>
              <AccordionIcon boxSize={8} mr={1} />
            </Flex>
          </AccordionButton>
          <AccordionPanel pb={4} px={0}>
            <PrimaryMenu {...category} onClose={modal.onClose} />
            <SecondaryMenu {...category} onClose={modal.onClose} mt={4} />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </>
  );
}
