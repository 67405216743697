import { StackProps, Text, VStack } from '@chakra-ui/react';

import type { CommonDocument } from '@raise/sanity';

import { FullLink, NormalLink } from './Links';

export const PrimaryMenu: React.FC<
  CommonDocument['header']['left'][number] & {
    onClose: () => void;
  }
> = ({ menu, color, onClose }) => (
  <VStack spacing={0}>
    {menu.primary.map((link) => (
      <FullLink key={link.title} color={color} onClose={onClose} {...link} />
    ))}
  </VStack>
);

export const SecondaryMenu: React.FC<
  Omit<StackProps, 'color'> &
    CommonDocument['header']['left'][number] & {
      onClose: () => void;
    }
> = ({ menu, color, onClose, ...props }) => (
  <VStack spacing={3} alignItems="flex-start" {...props}>
    {menu.secondary.map((link, i) =>
      link?.link ? (
        <NormalLink
          key={link.title}
          color={color}
          onClose={onClose}
          {...link}
        />
      ) : (
        <Text
          key={link.title}
          fontWeight="bold"
          letterSpacing="1px"
          fontSize="sm"
          color="gray.500"
          textTransform="uppercase"
          pt={i === 0 ? 0 : 3}
        >
          {link.title}
        </Text>
      ),
    )}
  </VStack>
);
