'use client';

import { Flex, IconButton, Image } from '@chakra-ui/react';
import NextLink from 'next/link';

import Icon from '@components/Icon';
import PageContainer from '@components/PageContainer';

import { useMobileContext } from './Provider';

import { PADDING } from '../_constants';

export default function MobileNavbar({ logo }: { logo: any }) {
  const props = useMobileContext();
  if (!props) return null;

  const { modal, buttonRef } = props;

  return (
    <PageContainer py={PADDING} display={['block', null, 'none']}>
      <Flex justifyContent="space-between" alignItems="center">
        <NextLink href="/">
          <Image src={logo.src} h={8} alt="Raise Financial" />
        </NextLink>
        <IconButton
          aria-label="Menu"
          variant="ghost"
          colorScheme="gray"
          icon={
            <Icon
              style="solid"
              icon={modal.isOpen ? 'times' : 'bars'}
              size="lg"
            />
          }
          onClick={modal.onToggle}
          ref={buttonRef}
        />
      </Flex>
    </PageContainer>
  );
}
