import imageUrlBuilder from '@sanity/image-url';
import { createClient } from '@sanity-typed/next-sanity';

import type { SanityValues } from '@raise/sanity';

// Create the client
export const client = createClient<SanityValues>()({
  projectId: 'cpupu8kq',
  dataset: process.env.NODE_ENV === 'production' ? 'production' : 'development',
  apiVersion: new Date().toISOString().slice(0, 10),
  useCdn: false,
});

// Create a URL builder from the client
const builder = imageUrlBuilder(client);

// Export a function to compose Sanity image URLs
export const sanityImage = (source: any) => builder.image(source);
