import { Accordion, Box, UseDisclosureReturn } from '@chakra-ui/react';

import PageContainer from '@components/PageContainer';

import LeftLinks from './LeftLinks';
import SessionLinks from './SessionLinks';

import { GetDataProps } from '../types';

export default function MobileContent({
  modal,
  common,
}: {
  modal: UseDisclosureReturn;
  common: GetDataProps['common'];
}) {
  return (
    <PageContainer>
      <Accordion allowToggle>
        <LeftLinks modal={modal} common={common} />
      </Accordion>
      <SessionLinks modal={modal} common={common} />
      <Box mt={4}>
        {/* TODO: @jaro @patrick this crashes the app due to server components - what can we do here? */}
        {/* <UserAvatar /> */}
      </Box>
    </PageContainer>
  );
}
