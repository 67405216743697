'use client';

import { useState } from 'react';
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tab,
  TabList,
  Tabs,
  useTabIndicator,
} from '@chakra-ui/react';
import { usePathname } from 'next/navigation';

import Icon from '@components/Icon';

import { PrimaryMenu, SecondaryMenu } from '../Menus';
import { GetDataProps } from '../types';

const CustomIndicator = (props: BoxProps) => {
  const { width, left, transitionProperty, ...indicator } = useTabIndicator();

  const transProps = transitionProperty?.split(', ') || [];

  if (!transProps.includes('background-color')) {
    transProps.push('background-color');
  }

  if (!transProps.includes('opacity')) {
    transProps.push('opacity');
  }

  return (
    // @ts-ignore Not sure what this is
    <Box
      mt={-2}
      height={1}
      borderRadius="full"
      {...indicator}
      {...props}
      w={`${width}px`}
      left={`${left}px`}
      transitionProperty={transProps.join(', ')}
    />
  );
};

export default function DesktopLeftLinks({
  common,
}: {
  common: GetDataProps['common'];
}) {
  const pathname = usePathname();
  const [index, setIndex] = useState(0);
  const [hasPopoverOpen, setHasPopoverOpen] = useState(false);

  if (!common) return null;

  const links = common?.header.left;
  const isActive = (link: string) => pathname!.includes(link);

  return (
    <Tabs
      index={index}
      onChange={setIndex}
      variant="unstyled"
      position="relative"
      ml={[4, null, null, 6]}
    >
      <TabList>
        {links.map((category) => (
          <Popover
            key={category.title}
            placement="bottom-start"
            arrowSize={10}
            gutter={16}
            isLazy
            onOpen={() => setHasPopoverOpen(true)}
            onClose={() => setHasPopoverOpen(false)}
          >
            {({ isOpen, onClose }) => (
              <>
                {/* @ts-ignore */}
                <PopoverTrigger>
                  <Tab
                    as={Box}
                    key={category.title}
                    px={0}
                    mx={[3, null, null, 4]}
                  >
                    <Button
                      variant="unstyled"
                      color={
                        isOpen || isActive(category.link)
                          ? category.color.value
                          : 'blackAlpha.700'
                      }
                      _hover={{
                        color:
                          isOpen || isActive(category.link)
                            ? category.color.value
                            : 'black',
                      }}
                      rightIcon={
                        <Icon
                          style="solid"
                          icon="chevron-right"
                          size="xs"
                          transform={isOpen ? 'rotate(90deg)' : undefined}
                          transition="transform 0.1s ease-in-out"
                        />
                      }
                    >
                      {category.title}
                    </Button>
                  </Tab>
                </PopoverTrigger>
                <PopoverContent borderColor="gray.200" width={640}>
                  <PopoverArrow />
                  <PopoverBody p={0}>
                    <Flex>
                      <Flex direction="column" p={6} justifyContent="center">
                        <PrimaryMenu {...category} onClose={onClose} />
                      </Flex>
                      <SecondaryMenu
                        {...category}
                        borderRightRadius="md"
                        bg="gray.100"
                        borderLeft="1px"
                        borderLeftColor="gray.200"
                        flexShrink={0}
                        flexBasis={190}
                        p={4}
                        onClose={onClose}
                      />
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        ))}
      </TabList>
      <CustomIndicator
        bg={links[index].color.value}
        opacity={hasPopoverOpen ? 1 : 0}
      />
    </Tabs>
  );
}
