'use client';

import { Button, Link } from '@chakra-ui/react';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';

import { GetDataProps } from '../types';

export default function SessionLinks({
  common,
}: {
  common: GetDataProps['common'];
}) {
  const { status } = useSession();
  const session = status === 'authenticated';
  const pathname = usePathname();

  if (!common) return null;

  const links = common?.header.right;
  const isActive = (link: string) => pathname!.includes(link);

  return (
    <>
      {!session &&
        links.map((link) => {
          if (link.is_button) {
            return (
              <Button as={NextLink} key={link.title} href={link.link}>
                {link.title}
              </Button>
            );
          }

          return (
            <Link
              as={NextLink}
              key={link.title}
              href={link.link}
              variant="unstyled"
              fontWeight="semibold"
              color={isActive(link.link) ? 'brand.500' : 'blackAlpha.700'}
              _hover={{
                color: isActive(link.link) ? 'brand.500' : 'black',
              }}
            >
              {link.title}
            </Link>
          );
        })}
    </>
  );
}
