'use client';

import React, { Context, createContext, useContext, useEffect } from 'react';
import Intercom, {
  boot,
  getVisitorId,
  hide,
  onHide,
  onShow,
  onUnreadCountChange,
  onUserEmailSupplied,
  show,
  showArticle,
  showConversation,
  showMessages,
  showNewMessage,
  showNews,
  showSpace,
  showTicket,
  shutdown,
  startChecklist,
  startSurvey,
  startTour,
  trackEvent,
  update,
} from '@intercom/messenger-js-sdk';

type IntercomProps = {
  boot: typeof boot;
  getVisitorId: typeof getVisitorId;
  hide: typeof hide;
  onHide: typeof onHide;
  onShow: typeof onShow;
  onUnreadCountChange: typeof onUnreadCountChange;
  onUserEmailSupplied: typeof onUserEmailSupplied;
  show: typeof show;
  showArticle: typeof showArticle;
  showConversation: typeof showConversation;
  showMessages: typeof showMessages;
  showNewMessage: typeof showNewMessage;
  showNews: typeof showNews;
  showSpace: typeof showSpace;
  showTicket: typeof showTicket;
  shutdown: typeof shutdown;
  startChecklist: typeof startChecklist;
  startSurvey: typeof startSurvey;
  startTour: typeof startTour;
  trackEvent: typeof trackEvent;
  update: typeof update;
};

const IntercomContext = createContext<Partial<IntercomProps>>({});

export const useIntercom = () =>
  useContext<IntercomProps>(IntercomContext as Context<IntercomProps>);

export default function IntercomProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // Intercom App ID
  const appId = process.env.NEXT_PUBLIC_INTERCOM_APPID ?? '';

  // Initialize Intercom
  Intercom({
    app_id: appId,
  });

  // Boot Intercom
  useEffect(() => {
    boot({
      app_id: appId,
    });
  }, [appId]);

  return (
    <IntercomContext.Provider
      value={{
        boot,
        getVisitorId,
        hide,
        onHide,
        onShow,
        onUnreadCountChange,
        onUserEmailSupplied,
        show,
        showArticle,
        showConversation,
        showMessages,
        showNewMessage,
        showNews,
        showSpace,
        showTicket,
        shutdown,
        startChecklist,
        startSurvey,
        startTour,
        trackEvent,
        update,
      }}
    >
      {children}
    </IntercomContext.Provider>
  );
}
